import { mapHelper } from "@/utils/utils.js";
const statusMps = [
  {
    value: 0,
    text: "已提交，待审核",
  },
  {
    value: 1,
    text: "已完成积分获取",
  },
  {
    value: 2,
    text: "审核失败，请重新提交",
  },
];
const { map: setStatusMps, setOps: setStatusMpsOps } =
  mapHelper.setMap(statusMps);
const appointStatus = [
  { key: 1, value: "待付款" },
  { key: 2, value: "已完成" },
  { key: 3, value: "已取消" },
  { key: 4, value: "待接收" },
  { key: 5, value: "已关闭" },
  { key: 11, value: "待确认" },
  { key: 12, value: "已驳回" },
  { key: 99, value: "已结束" },
];
export { statusMps, setStatusMps, setStatusMpsOps, appointStatus };
